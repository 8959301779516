@font-face {
    font-family: "Rock-SansBold";
    src: url("./fonts/Rock-SansBold.eot");
    src: url("./fonts/Rock-SansBold.woff2") format("woff2"),
        url("./fonts/Rock-SansBold.woff") format("woff"),
        url("./fonts/Rock-SansBold.ttf") format("truetype"),
        url("./fonts/Rock-SansBold.svg#Rock-SansBold") format("svg"),
        url("./fonts/Rock-SansBold.eot?#iefix") format("embedded-opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Rock-SansNormal";
    src: url("./fonts/Rock-SansNormal.eot");
    src: url("./fonts/Rock-SansNormal.woff2") format("woff2"),
        url("./fonts/Rock-SansNormal.woff") format("woff"),
        url("./fonts/Rock-SansNormal.ttf") format("truetype"),
        url("./fonts/Rock-SansNormal.svg#Rock-SansNormal") format("svg"),
        url("./fonts/Rock-SansNormal.eot?#iefix") format("embedded-opentype");
    font-weight: 400;
    font-style: normal;
}

/* common css start */
html {
    font-size: 14px;
}

body {
    font-family: "Rock-SansNormal";
    color: #4e535a;
    margin: 0;
    padding: 0;
    background: #f5f5f5;
    scroll-behavior: smooth;
}

article,
aside,
footer,
header,
nav,
section {
    display: block;
}

.row {
    margin: 0 auto;
    max-width: 1290px;
    width: 100%;
    display: block;
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

a {
    color: #400095;
    text-decoration: none;
    -webkit-transition: color .5s ease-in-out;
    -o-transition: color .5s ease-in-out;
    transition: color .5s ease-in-out;
}

a:hover {
    color: #400095;
}

strong {
    font-weight: bold;
}

img {
    height: auto;
    max-width: 100%;
}

form {
    overflow: hidden;
}

embed,
iframe,
object,
video {
    max-width: 100%;
}

:focus {
    outline-width: 2px;
    outline-color: #78aeda;
    outline-style: solid;
}

.no-padding {
    padding: 0px !important;
}

.full-width {
    width: 100% !important;
}

p {
    margin-bottom: 20px;
}

button,
select {
    text-transform: none;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
}

.color-red {
    color: #ff0000;
}

.color-black {
    color: #000;
}

.color-white {
    color: #fff;
}

.bg-primrary {
    background: #009dd6;
}

.text-decoration {
    text-decoration: underline;
}

.content-container {
    clear: both;
}

.border-none {
    border: none !important;
}

h1,
.h1 {
    font-size: 60px;
    font-family: "Rock-SansBold";
    line-height: 81px;
    margin: 0;

}

h2,
.h2 {
    font-size: 42px;
    line-height: 63px;
    margin: 0;
}

h3,
.h3 {
    font-size: 36px;
    line-height: 54px;
    margin: 0;
}

h4,
.h4 {
    font-size: 32px;
    line-height: 48px;
    margin: 0;
}

h5,
.h5 {
    font-size: 28px;
    line-height: 42px;
    margin: 0;
}

h6,
.h6 {
    font-size: 24px;
    line-height: 38px;
    margin: 0;
}

.container {
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 10px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

video {
    width: 100%;
}

main {
    min-height: 100vh;
}

input {
    appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    background: transparent;
    border-bottom: 1px solid #fbfbfb;
    color: #fbfbfb;
}

input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 21px;
    color: #fbfbfb;
    opacity: 1;
}

input::-moz-placeholder {
    font-size: 12px;
    line-height: 21px;
    color: #fbfbfb;
    opacity: 1;
}

input:-ms-input-placeholder {
    font-size: 12px;
    line-height: 21px;
    color: #fbfbfb;
    opacity: 1;
}

input:-moz-placeholder {
    font-size: 12px;
    line-height: 21px;
    color: #fbfbfb;
    opacity: 1;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.inner-container {
    padding: 20px 40px;
}

.primary-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 24.5px;
    color: #FFFFFF;
    padding: 5px 15px;
    background: #794DB5;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.primary-btn:hover {
    opacity: 0.8;
    color: #fff;
}

/* common css end */

/* header css start */
.header {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
}

body.inner-page .header {
    background: linear-gradient(90deg, #1F034E 16.82%, #38068C 82.83%);
}

.header-container {
    padding: 20px 40px;
}

.nabar-branding {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header-tel-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.search-bar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
}

.tel-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.search-bar input[type="text"] {
    width: 178px;
    font-size: 12px;
    line-height: 21px;
    background: transparent;
    padding: 0 20px;
}

.search-bar::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 4px;
    left: 1px;
    width: 12px;
    height: 12px;
    background: url("../../public/assets/images/icons/search-icon.svg") no-repeat;
    background-size: contain;
}

.tel-info-icon {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../public/assets/images/icons/tel-icon.svg") no-repeat;
    background-size: contain;
    margin-right: 8px;
}

.tel-info-text {
    font-size: 12px;
    line-height: 21px;
    font-family: "Rock-SansBold";
    color: #FBFBFB;
}

.tel-info-text:hover {
    color: #FBFBFB;
}

.hamburger-menu,
.mob-menu-close,
.mob-search-menu {
    display: none;
}

.header-menus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 10px;
}

.header-menu-items {
    position: relative;
    font-size: 14px;
    padding: 10px 16px;
    color: #fbfbfb;
    text-transform: uppercase;
}

.header-menu-items a {
    display: block;
    font-size: inherit;
    color: #FBFBFB;
    text-decoration: none;
    transition: color .5s ease-in-out;
}

.header-menu-items a:active,
.header-menu-items a:hover,
.header-menu-items:hover {
    color: #4DBAE2;
}

.header-menu-items button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

button span {
    margin-left: 3px;
}

.dropdown-arrow::after {
    content: "";
    display: none;
    width: 12px;
    height: 12px;
    border-right: 0.2em solid #fff;
    border-top: 0.2em solid #fff;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    margin: 2px 0 0 10px;
}

.sub-menu-dropdown {
    position: absolute;
    left: auto;
    top: 40px;
    z-index: 9999;
    min-width: 35rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #2c2f33;
    display: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.header-menu-items:nth-last-child(-n+2) .sub-menu-dropdown {
    right: 0;
}

.sub-menu-dropdown.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.sub-menu-dropdown .header-menu-items {
    width: 50%;
}

.sub-menu-dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

/* header css end */

/* home banner component css start */
.home-banner-section {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.home-banner-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 48.96%, rgba(0, 0, 0, 0.03) 100%);
    z-index: 1;
}

.full-banner-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
}

.full-width-wrapper {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.home-banner-section .desktop-banner-img {
    display: block;
}

.home-banner-section .mobile-banner-img {
    display: none;
}

.home-banner-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 22.38%, rgba(0, 0, 0, 0) 100%);

}

/* home banner component css end */

/* home grid component css start */
.home-grid-section {
    max-width: 1160px;
    width: 100%;
    margin: 80px auto;
}

.home-grid-section .section-title {
    font-size: 28px;
    font-family: "Rock-SansBold";
    line-height: 42px;
    color: #4E535A;
    padding: 0 20px;
}

.home-grid-section .section-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.home-grid-section .individual-section {
    display: block;
    width: calc(33.33% - 20px);
    position: relative;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
    height: 150px;
}

.home-grid-section .individual-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.home-grid-section .individual-section:hover::before {
    background: rgba(0, 0, 0, 0.3);
}

.home-grid-section .section-image img {
    height: 150px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.home-grid-section .section-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}

.home-grid-section .section-content h6 {
    font-family: "Rock-SansBold";
    font-size: 18px;
    line-height: 31.5px;
    color: #fff;
    padding: 10px;

}

/* home grid component css end */

/* country production component css start */
.main-banner-wrapper {
    position: relative;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, color-stop(16.82%, #1F034E), color-stop(82.83%, #38068C));
    background: -o-linear-gradient(left, #1F034E 16.82%, #38068C 82.83%);
    background: linear-gradient(90deg, #1F034E 16.82%, #38068C 82.83%);
    /* overflow: hidden; */
}

.banner-container {
    padding: 160px 40px 20px;
}

.banner-container .heading {
    line-height: 81px;
    color: #FBFBFB;
}

.search-banner-field {
    position: relative;
    width: 100%;
    margin: 20px 0 0;
}

.search-input {
    position: relative;
    width: 100%;
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    padding: 5px 40px 5px 0;
}

.search-input:-ms-input-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    opacity: 1;
}

.search-input::-webkit-input-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    opacity: 1;
}

.search-input:-moz-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    opacity: 1;
}

.search-input::-moz-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    opacity: 1;
}

.clear-search {
    position: absolute;
    top: 12px;
    right: 0;
    width: 24px;
    height: 24px;
}

.clear-search .close-icon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../../public/assets/images/icons/close-icon.svg") no-repeat;
    background-size: contain;
}

.production-details-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 110px 0 60px;
}

.filter-list-wrap {
    width: 20%;
}

.card-list {
    position: relative;
    width: 80%;
}

.filter-list-wrap .listing {
    margin-bottom: 30px;
}

.filter-list-wrap h6 {
    font-size: 12px;
    font-family: "Rock-SansBold";
    line-height: 21px;
    color: #4E535A;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}

.searh-result-text {
    font-family: "Rock-SansBold";
    line-height: 48px;
    color: #383B40;
}

.production-card-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.production-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    width: calc(33.33% - 20px);
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px;
    -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.production-card:hover {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.production-card .icon-lock {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 18px;
    background: url("../../public/assets/images/icons/lock-icon.svg") no-repeat;
    background-size: contain;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.production-img {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 65px;
}

.production-details {
    position: relative;
    margin: 15px 0 0;
}

.production-details::after {
    content: "";
    position: absolute;
    display: inline-block;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 15px;
    background: url("../../public/assets/images/icons/arrow-back.svg") no-repeat;
    background-size: contain;

}

.production-details .name {
    font-size: 18px;
    font-family: "Rock-SansBold";
    line-height: 31.5px;
    color: #43474D;
}

.role-details .name {
    font-size: 18px;
    font-family: "Rock-SansBold";
    line-height: 31.5px;
    color: #F1F1F2;
}

.city-details .name {
    font-size: 18px;
    font-family: "Rock-SansBold";
    line-height: 31.5px;
    color: #F1F1F2;
}

.production-card.role_guidance-card {
    background: #005E80;
    color: #FBFBFB;
    padding-top: 28px;
}

.production-card.role_guidance-card .icon-lock,
.production-card.city_guidance-card .icon-lock {
    visibility: hidden;
}

.production-card .production-details .city-name {
    color: #9A9FA6;
}

.production-card.role_guidance-card .production-details .city-name,
.production-card.city_guidance-card .production-details .city-name {
    color: #E2E4E6;
}

.city-name {
    text-transform: capitalize;
}

.production-card.role_guidance-card .production-details .name,
.production-card.city_guidance-card .production-details .name {
    color: #FBFBFB;
}

.production-card.city_guidance-card {
    background: #5319A0;
    color: #FBFBFB;
    padding-top: 28px;
}

/* custom checkboxes css */
.custom-checkbox {
    position: absolute;
    opacity: 0;
}

.custom-checkbox+label {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    font-family: "Rock-SansBold";
    color: #4E535A;
    cursor: pointer;
    padding: 0;
}

.custom-checkbox+label:before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 8px;
    border: 1px solid #8C9199;
    border-radius: 1px;
}

/* .custom-checkbox:hover+label:before {
    background: #4DBAE2;
} */

.custom-checkbox:checked+label:before {
    background: #4DBAE2;
    border: 1px solid #4DBAE2;
}

/* pagination css start */
.desktop-custom-pagination {
    display: block;
}

.mobile-custom-pagination {
    display: none;
}

.custom-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 60px 0 0;
}

.custom-pagination .page-arrows {
    position: relative;
    font-size: 0;
    width: 60px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #8C9199;
    border-radius: 5px;
}

.custom-pagination .page-arrows::before {
    content: "";
    position: absolute;
    display: inline-block;
    border: solid #8C9199;
    border-width: 0 3px 3px 0;
    padding: 4.5px;
    top: 50%;
    left: 50%;

}


.custom-pagination .page-arrows .arrow {
    display: inline-block;
    border: solid #8C9199;
    border-width: 0 3px 3px 0;
    padding: 4.5px;
}

.custom-pagination .page-arrows.page-previous::before {
    -ms-transform: translate(-50%, -50%) rotate(135deg);
    transform: translate(-50%, -50%) rotate(135deg);
    -webkit-transform: translate(-50%, -50%) rotate(135deg);
}

.custom-pagination .page-arrows.page-next::before {
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
}

.custom-pagination .page-arrows .title {
    font-size: 18px;
    line-height: 31.5px;
    color: #FBFBFB;
    display: none;
}

.custom-pagination .previous.disabled,
.custom-pagination .next.disabled {
    visibility: hidden;
}

.custom-pagination .page-count a {
    font-size: 18px;
    font-family: "Rock-SansBold";
    line-height: 31.5px;
    color: #8C9199;
    padding: 12px 21px;
    margin: 0 4px;
}

.custom-pagination .page-count.active a {
    background: #4DBAE2;
    color: #FBFBFB;
    border-radius: 5px;
}

.page-count-mobile {
    display: none;
}

/* country production component css end */

/* article component css start */
.main-inner-section .banner-container {
    padding-top: 130px;
}

.article-inner-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 0;
}

.article-section {
    width: 70%;
    margin-right: 50px;
}

.article-section h4 {
    font-family: "Rock-SansBold";
    color: #4E535A;
}

.article-timestamp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tags-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0;
}

.tags-name {
    display: inline-block;
    padding: 0 12px;
    font-size: 14px;
    line-height: 24.5px;
    color: #595E66;
    text-transform: uppercase;
    border: 1px solid #646A73;
    border-radius: 2px;
    margin: 12px 12px 12px 0;
}

.article-section .timestamp {
    font-size: 18px;
    line-height: 25px;
    color: #4E535A;
    padding-left: 11px;
    border-left: 1px solid #4E535A;
}

.article-main-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.article-content {
    width: 70%;
    margin-right: 50px;
}

.article-content,
.article-content p {
    font-size: 18px;
    line-height: 31.5px;
    color: #4E535A;
}

/* .article-content p {
    padding-bottom: 10px;
} */

.article-banner {
    width: 650px;
    margin: 5px 0 30px;
}

/* .resources-section {
    width: 25%;
    margin-top: 650px;
} */

.resources-section h6 {
    font-size: 12px;
    font-family: "Rock-SansBold";
    line-height: 21px;
    color: #4E535A;
    text-transform: uppercase;
}

.resources-list li a {
    font-size: 16px;
    line-height: 28px;
    color: #4E535A;
    font-family: "Rock-SansBold";
}

/* article component css end */

/* password protected component css start */
.password-content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 100px 10px 150px;
}

.password-content-wrap .heading {
    font-family: "Rock-SansBold";
    color: #383B40;
    margin: 30px 0 10px;
}

.password-content-wrap .incorrect-pwd {
    color: #330077;
}

.password-content-wrap .sub-heading {
    color: #595E66;
}

.password-content-wrap .help-text {
    font-size: 18px;
    line-height: 31.5px;
    color: #595E66;
    margin: 20px 0;
}

.passoword-form {
    position: relative;
    margin: 30px 0 60px;
}

.passoword-form input[type="password"] {
    width: 515px;
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    border-bottom: 1px solid #4E535A;
    padding: 5px;
    outline: none;
}

.password-field:-ms-input-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    opacity: 1;
}

.password-field::-webkit-input-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    opacity: 1;
}

.password-field:-moz-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    opacity: 1;
}

.password-field::-moz-placeholder {
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    opacity: 1;
}

.passoword-form .password-submit {
    position: absolute;
    right: 3px;
    font-size: 0;
    top: 15px;
}

.right-arrow {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../../public/assets/images/icons/arrow-right-dark.svg") no-repeat;
    background-size: contain;
}

/* password protected component css end */

/* Production component css start */
.productions-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 40px 0 110px
}

.productions-wrapper .details-section {
    width: 38%;
    margin-right: 30px;
    color: #4E535A;
}

.productions-wrapper .details-section h6 {
    font-size: 12px;
    font-family: "Rock-SansBold";
    line-height: 21px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.productions-wrapper .details-section p {
    font-size: 18px;
    line-height: 31px;
}

.productions-wrapper .accordion-section {
    width: 100%;
}

.contact-details .name {
    font-size: 18px;
    line-height: 31.5px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.contact-details .email a,
.contact-details .number a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 24.5px;
    margin-bottom: 0;
    color: #4E535A;
}

.contact-details .email,
.contact-details .number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
}


.contact-details .email {
    font-family: "Rock-SansBold";
}

/* accordion css */
.custom-accordion {
    background: #FBFBFB;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 31.5px;
    color: #06000F;
}

.custom-accordion .Collapsible__trigger.is-open .accordion-title:hover {
    background: #FBFBFB;
}

.custom-accordion .accordion-title:hover,
.custom-accordion .accordion-title:focus {
    background: #F7FCFE;
}

.accordion-title {
    position: relative;
    font-size: 18px;
    line-height: 31.5px;
    color: #06000F;
    padding: 15px 30px;
}

.accordion-title::after {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 15px;
    width: 25px;
    height: 25px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("../../public/assets/images/icons/accrodion-icon.svg") no-repeat;
    background-size: contain;
}

.custom-accordion .Collapsible__trigger.is-open .accordion-title::after {
    -webkit-transform: rotate(180deg) translateY(50%);
    -ms-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
}

.accordion-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 30px 30px;
}

.accordion-details .instruction-section {
    width: 45%;
    border-right: 1px solid #D4D6D9;
    padding: 0 20px 0 0;
}

.accordion-details .form-section {
    width: 51%;
    padding: 0 20px;
}

.accordion-details h6 {
    font-size: 12px;
    font-family: "Rock-SansBold";
    line-height: 21px;
    color: #4E535A;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.instruction-section .content,
.instruction-section .content p {
    font-size: 14px;
    line-height: 24.5px;
    color: #595E66;
    margin: 0 0 20px;
}

.instruction-section .content p {
    margin: 0;
}

.instruction-section .content ul {
    list-style: disc;
    padding: 0 0 0 20px;
}


.form-btns-wrap {
    margin-top: 10px;
}

.form-document-btn {
    display: table;
    font-size: 14px;
    line-height: 24.5px;
    color: #111213;
    border: 1px solid #D4D6D9;
    border-radius: 50px;
    padding: 8px 16px;
    background: #FBFBFB;
    margin-bottom: 10px;
    cursor: pointer;
}

.form-document-btn:hover {
    background: #f1f1f2;
    color: #111213;
}

.document-icon,
.pdf-icon,
.download-icon,
.link-icon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../../public/assets/images/icons/document-icon.svg") no-repeat;
    background-size: contain;
    margin-left: 15px;
    vertical-align: middle;
}

.pdf-icon {
    background: url("../../public/assets/images/icons/pdf-icon.svg") no-repeat;
}

.download-icon {
    background: url("../../public/assets/images/icons/download-icon.svg") no-repeat;
}

.link-icon {
    background: url("../../public/assets/images/icons/link-icon.svg") no-repeat;
}

.download-all-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 50px;
    display: table;
    margin-bottom: 10px;
}

.email-icon,
.phone-icon {
    position: relative;
    display: inline-block;
    padding-left: 30px;
}

.email-icon {
    background: url("../../public/assets/images/icons/email-icon.svg") no-repeat 3px 3px;
}

.phone-icon {
    background: url("../../public/assets/images/icons/phone-icon.svg") no-repeat 3px 3px;
}


/* Production component css end */

/* Role guidance component css start */
.department-names li {
    margin-bottom: 3px;
}

.department-names li a {
    font-size: 16px;
    font-family: "Rock-SansBold";
    line-height: 28px;
    color: #4E535A;
}

.department-names li a:hover {
    color: #4dbae2
}

.guidance-wrapper .title {
    font-size: 24px;
    line-height: 38px;
    color: #4E535A;
}

.guidance-wrapper .content {
    font-size: 18px;
    line-height: 31px;
    color: #4E535A;
}

.guidance-wrapper .guidance-text {
    font-size: 18px;
    font-family: "Rock-SansBold";
    line-height: 31px;
    color: #4E535A;
}

.guidance-wrapper .wrapper {
    margin: 30px 0 0 0;
}

.guidance-wrapper .accordion-details .form-section h6 {
    margin-bottom: 10px;
}

.btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.btn-wrap .role-guidance {
    margin: 5px;
}

/* Role guidance component css end */

/* Department components css start */
.department-wrapper {
    margin: 40px 0 30px;
    align-items: center;
}

.department-banner,
.department-headings {
    width: 50%;
}

.department-banner {
    margin: 0 60px 0 0;
}

.department-headings .heading {
    font-family: "Rock-SansBold";
    color: #383B40;
}

.department-headings h2 {
    color: #383B40;
    font-family: "Rock-SansBold";
    margin-bottom: 10px;
}

.department-headings p {
    font-size: 24px;
    line-height: 38.4px;
    color: #595E66;
}

.department-headings .descriptions {
    font-size: 24px;
    line-height: 38px;
    color: #595E66;
    margin: 20px 0;
}

.department-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 150px;
}

.overall-details {
    width: 65%;
    margin-right: 120px;
}

.department-desc .notes-details {
    width: 20%;
}
.notes-contact-details.notes-details ul li a {
    color: #400095;
}
.notes-contact-details.notes-details ul {
    list-style-type: disc;
    padding-left: 25px;
    font-size: 18px;
}
.notes-contact-details a {
    font-size: 18px;
    line-height: 31px;
    color: #4E535A;
}

.overall-details h5 {
    font-size: 24px;
    line-height: 38px;
    color: #4E535A;
    margin: 0 0 10px 0;
}

.individual-contact-list {
    margin-bottom: 24px;
}

.overall-details p {
    font-size: 18px;
    line-height: 31.5px;
    color: #4E535A;
    margin: 0 0 30px 0;
}

.overall-details .services p {
    margin: 0
}

.overall-details ul, .overall-details ol {
    padding-left: 25px;
    list-style: disc;
    font-size: 18px;
    line-height: 31px;
    color: #4E535A;
    margin: 0 0 30px 0;
}

.small-txt {
    font-size: 12px;
    line-height: 21px;
    color: #4E535A;
    text-transform: uppercase;
}

.contact-list {
    display: inline-block;
    width: 47%;
    margin: 10px;
}

.custom-links,
.custom-links a {
    color: #400095;
    font-size: 16px;
    line-height: 28px;
    font-family: "Rock-SansBold";
}

.custom-links a:hover {
    color: #400095;
    opacity: 0.8;
}

.custom-links .link-icon {
    width: 18px;
    height: 18px;
}

/* Department components css end */


/* search filter component css start */
.search-filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 22px 0 10px;
}

.search-filters .search-banner-field {
    width: 40%;
    margin: 0 10px 0 0;
}

.dropdown-filters-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 60%;
}

.search-filters .clear-search {
    right: 30px;
}

.search-filters .search-input {
    padding: 5px 60px 5px 0;
}

.search-filters .search-result {
    position: absolute;
    top: 15px;
    right: 0;
    width: 20px;
    height: 20px;
}

.search-filters .right-arrow {
    background: url("../../public/assets/images/icons/right-arrow-white.svg") no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
}

/* custom filter dropdown css start */
.custom-dropdown-container {
    position: relative;
    width: 100%;
    margin: 0 10px;
    border: 1px solid #FBFBFB;
    border-radius: 20px;
    cursor: pointer;
}

.custom-dropdown-input {
    position: relative;
    padding: 9px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-dropdown-input::after {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 20px;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("../../public/assets/images/icons/light-plus-icon.svg") no-repeat;
    background-size: contain;
}

.custom-dropdown-container:hover,
.custom-dropdown-container.active {
    background: #FBFBFB;
}

.custom-dropdown-container:hover .custom-dropdown-input::after,
.custom-dropdown-container.active .custom-dropdown-input::after {
    background: url("../../public/assets/images/icons/dark-plus-icon.svg") no-repeat;
}

.dropdown-selected-value {
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
}

.custom-dropdown-container:hover .dropdown-selected-value,
.custom-dropdown-container.active .dropdown-selected-value {
    color: #4E535A;
}

.custom-dropdown-menu {
    position: absolute;
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #D4D6D9;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background: #FBFBFB;
    overflow: auto;
    max-height: 160px;
    z-index: 99;
}

.custom-dropdown-item {
    padding: 10px;
    cursor: pointer;
    color: #4E535A;
    font-size: 20px;
    line-height: 32px;
}

.custom-dropdown-item:hover,
.custom-dropdown-item.selected {
    background: #E2E4E6;
}

/* custom filter dropdown css start */

.search-details-wrap {
    margin: 50px 0;
}

.search-result-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.search-result-title h4,
.no-result-text {
    font-family: "Rock-SansBold";
    color: #383B40;
}

.count-text {
    font-size: 18px;
    line-height: 31.5px;
    color: #4E535A;
}

.search-result-row {
    border-top: 1px solid #9A9FA6;
    padding: 25px 0;
    color: #4E535A;
}

.search-result-row:last-child {
    border-bottom: 1px solid #9A9FA6;
}

.search-result-row .title {
    display: block;
    position: relative;
    font-size: 24px;
    font-family: "Rock-SansBold";
    line-height: 38px;
    color: #4E535A;
}

.search-result-row .production-search-title,
.search-result-row .title-margin {
    margin-bottom: 20px;
}

.search-result-row .production-search-title {
    padding: 0 20px 0 0;
}

.search-result-row .production-search-title::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    width: 20px;
    height: 20px;
    background: url("../../public/assets/images/icons/lock-icon.svg") no-repeat;
    background-size: contain;
}

.search-result-row .content {
    font-size: 18px;
    line-height: 31.5px;
}

.search-result-row .content p {
    margin-bottom: 0;
}

.search-result-row .tags-wrapper {
    margin: 15px 0 25px;
}

.read-more-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.read-more-link a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Rock-SansBold";
    font-size: 20px;
    line-height: 32px;
    color: #400095;
}

.read-more-link a:hover {
    opacity: 0.6;
}

.right-arrow-blue {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../public/assets/images/icons/right-arrow-blue.svg") no-repeat;
    background-size: contain;
    margin-left: 5px;
}

/* footer component css start */
.footer {
    background: -webkit-gradient(linear, left top, right top, color-stop(22.74%, #1F034E), color-stop(79.15%, #38068C));
    background: -o-linear-gradient(left, #1F034E 22.74%, #38068C 79.15%);
    background: linear-gradient(90deg, #1F034E 22.74%, #38068C 79.15%);
}

.footer-container {
    padding: 20px;
}

.footer-menus .safety-txt {
    font-size: 16px;
    line-height: 28px;
    color: #FBFBFB;
    font-weight: 400;
    margin: 8px 0 0;
}

.footer-submenus {
    display: flex;
    margin: 35px 0 28px;
}

.footer-menu-list {
    width: 28%;
}

.footer-submenus .submenu-list li {
    margin: 0 15px 15px 0;
}

.footer-submenus .submenu-list li a,
.copyright-txt {
    font-size: 12px;
    line-height: 21px;
    color: #D9CCEA;
    text-transform: uppercase;
    padding: 5px 0
}

.copyright-txt {
    color: #B399D5;
}

.footer-submenus .submenu-list li a.choice-icon {
    background-position: left 0 top 5px;
    background-repeat: no-repeat;
    -webkit-background-size: 30px 14px;
    background-size: 30px 14px;
    padding: 5px 39px;
    background-image: url("../../public/assets/images/icons/privacy.svg");
}

.footer-submenus .submenu-list li a:hover {
    color: #4DBAE2;
}

.copyright-txt {
    margin: 8px 0 0;
}

/* footer component css start */

.basic-page-main a {
    word-break: break-word;
}

/* media query start */
@media screen and (min-width: 1800px) {
    .home-banner-section {
        height: 600px;
    }
}

@media screen and (max-width: 1239.98px) {

    /* header css */
    .header-container {
        padding: 20px;
    }

    .header-menu-items {
        padding: 10px 5px;
    }

    .sub-menu-dropdown .header-menu-items {
        padding: 10px 16px;
    }
}

@media screen and (max-width: 1109px) {
    .footer-submenus .submenu-list li {
        margin: 0 20px;
    }
}

@media screen and (max-width: 1024px) {

    /* header css */
    .search-bar,
    .tel-wrap {
        display: none;
    }

    .search-bar.active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        top: 130px;
        left: 50%;
        width: 80%;
        z-index: 999;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .search-bar input[type="text"] {
        width: 100%;
        color: #fbfbfb;
        font-size: 18px;
        line-height: 31.5px;
        padding: 0 10px 0 28px;
    }

    input::-webkit-input-placeholder {
        font-size: 18px;
        line-height: 31.5px;
        color: #fbfbfb;
        opacity: 1;
    }

    input::-moz-placeholder {
        font-size: 18px;
        line-height: 31.5px;
        color: #fbfbfb;
        opacity: 1;
    }

    input:-ms-input-placeholder {
        font-size: 18px;
        line-height: 31.5px;
        color: #fbfbfb;
        opacity: 1;
    }

    input:-moz-placeholder {
        font-size: 18px;
        line-height: 31.5px;
        color: #fbfbfb;
        opacity: 1;
    }

    .search-bar::before {
        top: 7px;
        width: 15px;
        height: 15px;
    }

    .header {
        background: rgba(0, 0, 0, 0.8);
    }

    .header-container {
        padding: 14px 40px;
    }

    .nabar-branding {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    body.scroll-hide {
        overflow-y: hidden;
    }

    body.inner-page .header {
        background: rgba(0, 0, 0, 0.8);
    }

    body.inner-page .header::after {
        content: "";
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        height: 94px;
        background: -webkit-gradient(linear, left top, right top, color-stop(34.41%, #1E024D), color-stop(75.1%, #37058B));
        background: -o-linear-gradient(left, #1E024D 34.41%, #37058B 75.1%);
        background: linear-gradient(90deg, #1E024D 34.41%, #37058B 75.1%);
    }

    /* mob hamburger menu */
    .hamburger-menu {
        display: inline-block;
    }

    .menu-nav-links {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: -webkit-gradient(linear, left top, right top, color-stop(34.41%, #1E024D), color-stop(75.1%, #37058B));
        background: -o-linear-gradient(left, #1E024D 34.41%, #37058B 75.1%);
        background: linear-gradient(90deg, #1E024D 34.41%, #37058B 75.1%);
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: -webkit-transform .2s ease-in-out;
        -webkit-transition: -webkit-transform .2s ease-in-out;
        -o-transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
        z-index: 99;
        overflow-y: auto;
    }

    .menu-nav-links.active {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        transition: -webkit-transform .2s ease-in-out;
        -webkit-transition: -webkit-transform .2s ease-in-out;
        -o-transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    }

    .menu-nav-links.active .header-menus {
        display: block;
        margin: 140px 0 0;
        padding: 35px 75px 10px;
    }

    .mob-menu-close {
        display: inline-block;
        position: absolute;
        top: 32px;
        right: 32px;
        width: 32px;
        height: 32px;
    }

    .header-menu-items {
        padding: 8px 0;
        font-size: 18px;
        line-height: 31.5px;
        color: #FBFBFB;
    }

    .sub-menu-dropdown {
        position: relative;
        top: 0;
    }

    .sub-menu-dropdown.show {
        display: block;
        min-width: 100%;
        background: transparent;
        width: 100%;
    }

    .sub-menu-dropdown .header-menu-items {
        width: 100%;
        font-size: 16px;
        line-height: 28px;
        padding: 8px;
    }

    .dropdown-arrow::after {
        display: inline-block;
    }

    .header-menu-items button[aria-expanded="true"] .dropdown-arrow::after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin: 0 0 0 13px;
    }

    .mob-search-menu {
        display: inline-block;
        padding: 0px 75px;
        margin: 0 0 85px 0;
    }

    .mob-search-menu img {
        width: 24px;
        height: 24px;
    }

    .mob-navbar.active,
    .mob-search-menu.active {
        display: none;
    }

    .home-banner-section {
        height: 800px;
    }

    .home-banner-section::before {
        display: none;
    }

    .home-banner-section .desktop-banner-img {
        display: none;
    }

    .home-banner-section .mobile-banner-img {
        display: block;
        object-fit: initial;
    }

    /* home grid component css */
    .home-grid-section {
        max-width: 100%;
        margin: 40px auto;
    }

    .home-grid-section .section-title {
        padding: 0 40px;
    }

    .home-grid-section .section-list {
        display: block;
        padding: 0 20px;
        width: 100%;
        height: 340px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .home-grid-section .individual-section {
        display: inline-block;
        width: 35%;
        height: 336px;
        overflow: hidden;
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .home-grid-section .section-image img {
        height: 336px;
    }

    .home-grid-section .section-content {
        top: auto;
        left: 20px;
        -webkit-transform: initial;
        -ms-transform: initial;
        transform: initial;
        bottom: 15px;
        white-space: initial;
        text-align: left;
    }

    /* country production component css */
    .banner-container {
        padding: 95px 40px 20px;
    }

    .banner-container .heading {
        font-size: 42px;
        line-height: 63px;
    }

    .inner-container {
        position: relative;
    }

    .production-details-wrap {
        display: block;
        margin: 190px 0 30px;
    }

    .filter-list-wrap,
    .card-list {
        width: 100%;
    }

    .filter-list-wrap {
        position: absolute;
        top: -1px;
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: -webkit-gradient(linear, left top, right top, color-stop(16.82%, #1F034E), color-stop(82.83%, #38068C));
        background: -o-linear-gradient(left, #1F034E 16.82%, #38068C 82.83%);
        background: linear-gradient(90deg, #1F034E 16.82%, #38068C 82.83%);
    }

    .filter-list-wrap .listing {
        position: relative;
        width: 42%;
        margin: 20px 15px 30px;
    }

    .filter-list-wrap .listing.active h6 {
        background: #FBFBFB;
        color: #4E535A;
    }

    .filter-list-wrap h6 {
        font-size: 20px;
        line-height: 32px;
        color: #FFFFFF;
        text-transform: capitalize;
        border: 1px solid #FBFBFB;
        border-radius: 20px;
        padding: 9px 20px;
        margin: 0;
    }

    .filter-list-wrap h6::after {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 20px;
        width: 20px;
        height: 20px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background: url("../../public/assets/images/icons/light-plus-icon.svg") no-repeat;
        background-size: contain;
    }

    .filter-list-wrap .listing.active h6::after {
        background: url("../../public/assets/images/icons/dark-plus-icon.svg") no-repeat;
    }

    .filter-checkbox-list {
        display: none;
        position: absolute;
        top: 58px;
        left: 0;
        width: 100%;
        border: 1px solid #D4D6D9;
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        background: #FBFBFB;
        z-index: 10;
    }

    .filter-checkbox-list li {
        padding: 10px;
    }

    .filter-list-wrap .listing.active .filter-checkbox-list {
        display: block;
    }

    .filter-checkbox-list .custom-checkbox+label {
        font-size: 20px;
        font-family: "Rock-SansNormal";
        line-height: 32px;
    }

    .production-details {
        text-align: center;
    }

    .production-details .city-name,
    .production-details::after {
        display: none;
    }

    /* pagination css */
    .desktop-custom-pagination {
        display: none;
    }

    .mobile-custom-pagination {
        display: block;
    }

    .custom-pagination .page-count-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 25px;
    }

    .custom-pagination .page-count-mobile span {
        font-size: 18px;
        line-height: 31.5px;
        color: #000000;
        padding: 5px 2px;
    }

    .count-text {
        margin: 20px 0 0;
    }

    .custom-pagination .page-arrows::before {
        display: none;
    }

    .custom-pagination .page-arrows {
        background: #4DBAE2;
        border: none;
        width: auto;
        height: auto;
        padding: 9px 22px;
    }

    .custom-pagination .page-arrows .title {
        display: block;
        margin: 0;
    }

    button.page-arrows:disabled {
        display: none;
    }

    /* article component css end */
    .main-inner-section .banner-container {
        padding-top: 160px;
    }

    .article-inner-section {
        position: relative;
        display: block;
        padding-top: 60px;
    }

    .article-section {
        width: 100%;
        margin-right: 0;
    }

    .article-section .timestamp {
        position: absolute;
        top: 15px;
        left: 0;
        padding: 0;
        border: none;
    }

    .article-banner,
    .article-banner img {
        width: 100%;
    }

    .article-main-content {
        display: block;
    }

    .article-content {
        width: 100%;
        margin-right: 0;
    }

    .resources-section {
        width: 100%;
        margin-top: 0;
    }

    .article-inner-section .resources-section {
        margin-top: 20px;
    }

    .article-section .contact-details {
        margin-top: 20px;
    }

    .contact-details .name {
        font-size: 16px;
        line-height: 28px;
    }

    /* article component css start */


    /* password entry component css start */
    .password-content-wrap {
        margin: 50px 10px;
        text-align: center;
    }

    .banner-container .heading {
        font-size: 36px;
        line-height: 54px;
    }

    .password-content-wrap .sub-heading {
        font-size: 20px;
        line-height: 32px;
    }

    .passoword-form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 50px 0 20px;
    }

    .passoword-form input[type="password"] {
        width: 310px;
        font-size: 20px;
        line-height: 32px;
    }

    .password-field:-ms-input-placeholder {
        font-size: 20px;
        line-height: 32px;
        color: #595E66;
        opacity: 1;
    }

    .password-field::-webkit-input-placeholder {
        font-size: 20px;
        line-height: 32px;
        color: #595E66;
        opacity: 1;
    }

    .password-field:-moz-placeholder {
        font-size: 20px;
        line-height: 32px;
        color: #595E66;
        opacity: 1;
    }

    .password-field::-moz-placeholder {
        font-size: 20px;
        line-height: 32px;
        color: #595E66;
        opacity: 1;
    }

    .passoword-form .right-arrow {
        display: none;
    }

    .passoword-form .password-submit {
        position: relative;
        right: initial;
        top: initial;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        background: #794DB5;
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 5px 15px;
        margin: 30px 0 10px;
    }

    .password-content-wrap .help-text {
        max-width: 354px;
        width: 100%;
        font-size: 16px;
        line-height: 28px;
    }

    /* password entry component css end */

    /* Production component css start */
    .banner-heading {
        padding: 150px 40px 80px;
    }

    .banner-container.banner-heading .heading {
        font-size: 42px;
        line-height: 63px;
    }

    .productions-wrapper {
        display: block;
        margin: 20px 0 90px;
    }

    .productions-wrapper .details-section,
    .productions-wrapper .accordion-section {
        width: 100%;
        margin-right: 0;
    }

    .productions-wrapper .accordion-section {
        margin-top: 30px;
    }

    .accordion-title {
        padding: 15px 20px;
    }

    .accordion-details {
        display: block;
        padding: 0 20px 20px;
    }

    .accordion-details .instruction-section {
        width: 100%;
        border-right: none;
        padding: 0
    }

    .accordion-details .form-section {
        width: 100%;
        padding: 0;
        margin-top: 20px;
    }

    .form-btns-main {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .productions-wrapper .details-section.hide-mob-details-section {
        display: none;
    }

    /* Production component css end */

    /* Department components css start */
    .department-banner {
        margin: 0;
    }

    .department-banner,
    .department-headings {
        width: 100%;
    }

    .department-banner img {
        width: 100%;
    }

    .department-headings .heading {
        margin-top: 20px;
    }

    .department-wrapper {
        display: none;
        margin: 20px 0 30px;
    }

    .department-desc {
        display: block;
    }

    .overall-details,
    .department-desc .notes-details {
        width: 100%;
        margin-right: 0;
    }

    .department-desc .notes-details {
        display: none;
        margin-top: 30px;
    }

    /* Department components css end */

    /* search component css start */
    .search-filters {
        display: block;
        margin: 40px 0 10px;
    }

    .search-filters .search-banner-field {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .dropdown-filters-wrap {
        width: 100%;
    }

    .search-result-title h4 {
        font-size: 28px;
        line-height: 36px;
    }

    .count-text {
        font-size: 16px;
        line-height: 24px;
    }

    .search-result-row {
        border-top: none;
        padding: 15px 20px;
        color: #4E535A;
        background: #FBFBFB;
        border-radius: 5px;
        margin: 20px 0;
    }

    .search-result-row .title {
        margin: 0 0 10px 0;
    }

    .search-result-row:last-child {
        border: none;
    }

    .search-result-row .production-search-title::after {
        top: 5px;
    }

    .search-result-row .read-more-link {
        display: none;
    }

    .search-result-row .tags-wrapper {
        margin: 15px 0 0;
    }

    .custom-pagination .page-arrows::before {
        border: solid #FBFBFB;
        border-width: 0 3px 3px 0;
    }

    /* search component css end */

    /* footer component css */
    .footer-container {
        padding: 60px 35px;
    }

    .footer-menu-list {
        width: 45%;
    }

    .footer-menus,
    .footer-submenus .submenu-list {
        display: block;
    }

    .footer-submenus .submenu-list li {
        margin: 0px 15px 15px 0;
    }

    .footer-submenus .submenu-list li a,
    .copyright-txt {
        font-size: 12px;
        line-height: 21px;
    }
}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 767.98px) {

    /* header css */
    .header-container {
        padding: 16px 20px;
    }

    body.inner-page .header::after {
        top: 53px;
        height: 50px;
    }

    .header-container .main-logo img {
        max-width: 249px;
    }

    .menu-nav-links.active .header-menus {
        padding: 35px 44px 15px;
    }

    .mob-search-menu {
        padding: 0 44px 44px;
    }

    .home-banner-section {
        height: 670px;
    }

    /* home grid component css  */
    .home-grid-section .section-title {
        padding: 0 20px;
    }

    .home-grid-section .section-list {
        height: auto;
        overflow-x: initial;
        overflow-y: initial;
        white-space: initial;
    }

    .home-grid-section .individual-section {
        display: block;
        width: 100%;
        height: 100px;
        overflow: auto;
        margin: 0 0 30px;
    }

    .home-grid-section .section-image img {
        height: 100px;
    }

    .home-grid-section .section-content {
        top: 50%;
        left: 50%;
        bottom: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .main-inner-section .banner-container {
        padding: 120px 20px 20px;
    }

    /* country productino css */
    .banner-container .heading {
        font-size: 28px;
        line-height: 42px;
    }

    .banner-container {
        padding: 95px 20px 20px;
    }

    .search-input {
        font-size: 18px;
        line-height: 31px;
    }

    .search-input:-ms-input-placeholder {
        font-size: 18px;
        line-height: 31px;
    }

    .search-input::-webkit-input-placeholder {
        font-size: 18px;
        line-height: 31px;
    }

    .search-input:-moz-placeholder {
        font-size: 18px;
        line-height: 31px;
    }

    .search-input::-moz-placeholder {
        font-size: 18px;
        line-height: 31px;
    }

    .clear-search .close-icon {
        width: 20px;
        height: 20px;
    }

    .filter-list-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0 0 20px;
    }

    .filter-list-wrap .listing {
        position: relative;
        width: 90%;
        margin: 10px 20px 5px;
    }

    .filter-list-wrap h6 {
        font-size: 18px;
        line-height: 31.5px;
    }

    .production-details-wrap {
        margin: 165px 0 60px;
    }

    .production-card {
        width: 100%;
    }

    /* article component css */
    .article-inner-section {
        padding-top: 30px;
    }


    .article-section h4 {
        font-size: 28px;
        line-height: 42px;
    }

    .article-section .timestamp {
        top: 0;
    }

    .article-banner {
        margin: 5px 0 15px;
    }

    .tags-wrapper {
        margin: 10px 0;
    }

    .article-content,
    .article-content p {
        font-size: 16px;
        line-height: 28px;
    }

    .resources-section h6 {
        margin-bottom: 5px;
    }

    .article-section .contact-details {
        margin-top: 10px;
    }


    /* password entry component css start */
    .password-content-wrap {
        margin: 50px 18px;
    }

    .password-content-wrap .icon {
        width: 35px;
    }

    .banner-container .heading {
        font-size: 24px;
        line-height: 38px;
    }

    .password-content-wrap .sub-heading {
        font-size: 14px;
        line-height: 24.5px;
    }

    .password-content-wrap .help-text {
        font-size: 14px;
        line-height: 24.5px;
    }

    /* password entry component css end */

    /* Production component css start */
    .banner-heading {
        padding: 140px 20px 110px;
    }

    .banner-container.banner-heading .heading {
        font-size: 28px;
        line-height: 42px;
    }

    .inner-container {
        padding: 20px;
    }

    .productions-wrapper .details-section p {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-details {
        padding-top: 10px;
    }

    .contact-details .name {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-details .email a,
    .contact-details .number a {
        font-size: 14px;
        line-height: 24.5px;
    }

    /* Production component css end */

    .guidance-wrapper .title {
        font-size: 18px;
    }

    .guidance-wrapper .content,
    .guidance-wrapper .guidance-text {
        font-size: 16px;
        line-height: 28px;
    }

    /* Department components css start */
    .overall-details h5 {
        font-size: 20px;
        line-height: 32px;
    }

    .overall-details p,
    .overall-details ul,
    .overall-details ol
    .contact-details .contact-list .name {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-list {
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    .department-desc {
        margin-bottom: 80px;
    }

    /* Department components css end */

    /* search component css start */
    .dropdown-filters-wrap {
        display: block;
    }

    .custom-dropdown-container {
        margin: 20px 0;
    }

    .dropdown-selected-value {
        font-size: 18px;
        line-height: 31.5px;
    }

    .search-details-wrap {
        margin: 0 0 50px 0;
    }

    .search-result-title {
        display: block;
    }

    .search-result-row .title {
        font-size: 18px;
        line-height: 31.5px;
        margin: 0;
        color: #595E66;
    }

    .search-result-row .content {
        display: none;
    }

    .search-result-row .tags-wrapper {
        display: none;
    }

    /* search component css end */

    /* footer component css start */
    .footer-submenus {
        display: block;
    }

    .footer-menu-list {
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .menu-nav-links.active .header-menus {
        padding: 35px 25px 15px;
    }

    .mob-search-menu {
        padding: 0 25px 25px;
    }
}

/* media query end */